import React from "react";
import ScrollAnimation from 'react-animate-on-scroll'
import "animate.css/animate.min.css";

class Hero extends React.Component {
  render() {
    const { location, title } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let heroHome

    if (location.pathname === rootPath) {
      heroHome = (
        <div id="hero">
          <div
            id="hero-banner"
            style={{
              backgroundImage:
                'url("/img/springs-body-sculpting-is-it-real.jpg")',
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat"
            }}
          />
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <ScrollAnimation animateIn="fadeInUpBig" animateOnce={true}>
                <div
                  data-aos="fade-up"
                  data-aos-once="true"
                  className="hero-content pr-md-5"
                >
                  <h1 className="hero-title text-primary-blue mb-3">
                    Start your CoolSculpting journey to a slimmer you
                  </h1>
                  <h4 className="hero-text">
                    Schedule a complimentary consultation with Springs Body
                    Sculpting in Colorado Springs today to learn if you are a good
                    candidate.
                  </h4>
                  <div className="d-flex align-items-start mt-4">
                    <a
                      className="btn-link bg-gradient-orange text-white hvr-grow-shadow hvr-pulse-shrink"
                      href="/"
                    >
                      Make an Appointment Now
                    </a>
                  </div>
                </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      heroHome = (
        <h3
          style={{
            fontFamily: `Montserrat, sans-serif`,
            marginTop: 0,
          }}
        >
            {title}
        </h3>
      )
    }
    return (
      <>
      {heroHome}
      </>
    );
  }
}

export default Hero;
