import React from "react";
import { Link } from "gatsby";
import { Nav, Dropdown, NavItem } from 'react-bootstrap'
import './menu.css'


export default () => (

  <div className="navigation">
    <Nav className="flex-column main-nav">
      <Nav.Item><Link to="/">Home</Link></Nav.Item>
      <Nav.Item><Link to="/">About Us</Link></Nav.Item>
      <Dropdown as={NavItem}>
        <Dropdown.Toggle as={Nav.Link}>Body Shaping Treatments</Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item>CoolSculpting Procedure</Dropdown.Item>
          <Dropdown.Item>Emsculpt Procedure</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Nav.Item><Link to="/">Blog</Link></Nav.Item>
      <Nav.Item><Link to="/">Testimonials</Link></Nav.Item>
      <Nav.Item><Link to="/">FAQS</Link></Nav.Item>
    </Nav>
    <ul className="list-unstyled text-white nav-info mt-4">
            <li>
              <img className="mb-0" width={18} height={18} src="/img/002-pin.svg" alt="location"/>
              <span>
                265 South Parkside Dr, Ste 100 Colorado Springs, CO 80910
              </span>
            </li>
            <li title="telephone">
              <img className="mb-0" width={18} height={18} src="/img/003-keypad.svg" alt="keypad" />
              <a className="text-white" href="tel://7196338773">
                719-633-8773
              </a>
            </li>
            <li>
              <img className="mb-0" width={18} height={18} src="/img/002-envelope.svg" alt="email"/>
              <a
                className="text-white"
                href="mailto:contact@SpringsBodySculpting.com"
                title="email"
              >
                contact@springsbodysculpting.com
              </a>
            </li>
          </ul>
          <div className="d-flex align-items-start mt-4">
            <a
              className="btn-link bg-gradient-orange text-white hvr-grow-shadow"
              href="/"
            >
              Make an Appointment
            </a>
          </div>
  </div>
)
