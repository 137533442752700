import React from "react"
import { Link } from 'gatsby'
import Sticky from 'react-sticky-el'
import SideBarMenu from './Sidebar'
import styl from './header.module.css'

const Header = () => (
  <header>
    <div className="d-none d-sm-block top-header" style={{ 
      background: '#008bdb linear-gradient(to right, #008bdb, #03b9ef)',
      padding: '1rem 0'
    }}>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <p className="mb-0 text-white schedule">
              <img
                className="mr-1 mb-0"
                src="/img/002-envelope.svg"
                width={16}
                height={16}
                alt="email"
              />
              <a
                className="text-white"
                href="mailto:contact@springsbodysculpting.com"
                title="email"
              >
                contact@springsbodysculpting.com
              </a>
            </p>
          </div>
          <div className="col-md-6">
            <p className="text-right mb-0 text-white schedule">
              <img
                className="mr-1 mb-0"
                src="/img/clock-circular-outline.svg"
                width={16}
                height={16}
                alt="clock"
              />
              Mon-Fri: 8am - 4:30pm
            </p>
          </div>
        </div>
      </div>
    </div>
    <Sticky 
      className={styl.stickyWrapper}
      stickyClassName={styl.isSticky}
      stickyStyle={{ transform: 'unset', zIndex: '2' }}
      >
        <div className={`shadow-sm bg-white ${styl.menuContainer}`}>
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-9 col-md-4">
                <div className={styl.navBrand}>
                  <Link to="/">
                  <img
                    className="img-fluid mb-0"
                    src="/img/colorado-springs-body-sculpting.png"
                    alt="logo"
                  />
                  </Link>
                </div>
              </div>
              <div className="col-2 col-md-8 d-flex justify-content-end align-items-center ml-auto">
                <p className="d-none d-md-inline mb-0 mr-3">
                  <a className="telephone" href="tel://7196338773">
                    <img
                      className="mr-1 mb-0"
                      src="/img/phone-receiver.svg"
                      alt="phone"
                      width={18}
                    />
                    719 - 633 - 8773
                  </a>
                </p>
                <SideBarMenu />
              </div>
            </div>
          </div>
        </div>
    </Sticky>
  </header>
)

export default Header
