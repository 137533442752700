import React from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import Header from './Header'
import Footer from './Footer'
import Hero from './Hero'
import './global.css'

class Layout extends React.Component {
  render() {
    const { location, children } = this.props
    return (
      <div>
        <Header/>
        <Hero location={location} />
        <main>
          {children}
        </main>
        <Footer/>
      </div>
    )
  }
}

export default Layout
