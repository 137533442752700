import React from "react";
import { Link } from 'gatsby'

class Footer extends React.Component {
  render() {
    return (
      <footer className="pt-5 bg-primary-blue">
        <div id="footer" className="my-4">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-lg-4 mb-4">
                <h4 className="mb-4 text-white font-weight-medium">
                  Contact Info
                </h4>
                <ul className="list-unstyled text-white footer-info">
                  <li>
                    <img
                      className="mb-0"
                      width={18}
                      height={18}
                      src="/img/002-pin.svg"
                      alt="location"
                    />
                    <span>
                      265 South Parkside Dr, Ste 100 Colorado Springs, CO 80910
                    </span>
                  </li>
                  <li>
                    <img
                      className="mb-0"
                      width={18}
                      height={18}
                      src="/img/003-keypad.svg"
                      alt="dialpad"
                    />
                    <a className="text-white" href="tel://7196338773" title="telephone">
                      719-633-8773
                    </a>
                  </li>
                  <li>
                    <img
                      className="mb-0"
                      width={18}
                      height={18}
                      src="/img/002-envelope.svg"
                      alt="email"
                    />
                    <a
                      className="text-white"
                      href="mailto:contact@SpringsBodySculpting.com"
                      title="email"
                    >
                      contact@SpringsBodySculpting.com
                    </a>
                  </li>
                </ul>
                <h5 className="text-white font-weight-medium">
                  Openning Hours
                </h5>
                <p className="text-white">
                  Monday – Friday
                  <br />
                  8am – 4:30pm
                </p>
                <ul className="list-unstyled d-flex">
                  <li className="mr-2">
                    <a
                      href="https://www.facebook.com/Springs-Body-Sculpting-1614356318868823/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="mb-0"
                        src="/img/001-facebook.svg"
                        width={40}
                        height={40}
                        alt="facebook"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/springs_body_sculpting/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="mb-0"
                        src="/img/instagram.svg"
                        width={40}
                        height={40}
                        alt="instagram"
                      />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-md-6 col-lg-2 mb-4">
                <h4 className="mb-4 text-white font-weight-medium">
                  Quick Links
                </h4>
                <ul className="list-unstyled text-white">
                  <li>
                    <Link className="text-white" to="/">
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link className="text-white" to="/">
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link className="text-white" to="/">
                      Blog
                    </Link>
                  </li>
                  <li>
                    <Link className="text-white" to="/">
                      Testimonials
                    </Link>
                  </li>
                  <li>
                    <Link className="text-white" to="/">
                      Faqs
                    </Link>
                  </li>
                  <li>
                    <a className="text-white" href="https://visitor.r20.constantcontact.com/manage/optin?v=0015vUZnZWeEJuMzC-RPdaFKbcOIxIW-f5WIJ8tZG_dJZD6M4kw72OBZ_gcgnQkYJNKPoeLPg131eAO02e2ZT8Y-iExypxXDlEcLkBun9cQnlH89xnT0MOd7J5cKy_-fsQYGCCCp2WEiewMpYCOaiwePycx2cO5SuavhJBwhfR_wMFbzKZM1omdb19Jqx9-A47L0wVBkfKecbwNkZH1eZFH7g%3D%3D" target="_blank" rel="noopener noreferrer">
                      Join The Mailing List
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 mb-4">
                <h4 className="mb-4 text-white font-weight-medium">
                  Body Shaping Treatments
                </h4>
                <ul className="list-unstyled text-white">
                  <li>
                    <Link className="text-white" to="/">
                      CoolSculpting Procedure
                    </Link>
                  </li>
                  <li>
                    <Link className="text-white" to="/">
                      Emsculpt Procedure
                    </Link>
                  </li>
                </ul>
                <div className="d-flex justify-content-start align-items-start mt-4">
                  <a
                    className="btn-link bg-gradient-orange text-white hvr-grow-shadow"
                    href="/"
                  >
                    Make an appointment
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="copyright" className="pb-5 pt-4">
          <div className="container">
            <p className="text-white mb-0">
              © Copyright {new Date().getFullYear()} - Spring Body Sculpting. - All Rights Reserved -
              Designed by <a
                className="text-white"
                href="https://www.webriq.services/"
                target="_blank"
                rel="noopener noreferrer"
                title="webriq goes mad"
              >
                WebriQ Goes Mad
              </a> - Powered by <a
                className="text-white"
                href="https://www.webriq.com/"
                target="_blank"
                rel="noopener noreferrer"
                title="webriq"
              >
                WebriQ
              </a>
            </p>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
